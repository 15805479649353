export default [
  {
    header: 'Super Admin',
    icon: 'UsersIcon',
  },
  {
    title: 'Level',
    route: 'route-level',
    icon: 'ListIcon',
  },
  {
    title: 'Menu',
    route: 'route-menu',
    icon: 'ListIcon',
  },
  {
    title: 'Role Management',
    route: 'route-role',
    icon: 'ListIcon',
  },
  {
    title: 'Status Management',
    route: 'route-statusrole',
    icon: 'ListIcon',
  },
  {
    header: 'All',
    icon: 'UsersIcon',
  },
  {
    title: 'Dashboard',
    route: 'dashboard-ecommerce',
    icon: 'HomeIcon',
  },
  // Sales
  {
    header: 'Sales',
    icon: 'UsersIcon',
  },
  {
    title: 'Ajuan Barang',
    route: 'route-ajuan-barang',
    icon: 'PackageIcon',
  },
  {
    title: 'Ajuan BOP',
    route: 'route-biaya',
    icon: 'FileTextIcon',
  },
  {
    title: 'Data Konsumen',
    route: 'route-konsumen',
    icon: 'UsersIcon',
  },
  {
    title: 'Ajuan Konsumen',
    route: 'route-ajuan-konsumen',
    icon: 'UserCheckIcon',
  },
  {
    title: 'Data Transaksi',
    route: 'route-transaksi',
    icon: 'FileTextIcon',
  },
  {
    title: 'Data Hutang',
    route: 'route-hutang',
    icon: 'FileTextIcon',
  },
  {
    title: 'Data Penjualan',
    route: 'route-penjualan',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Data Analisis',
    route: 'route-analisis',
    icon: 'TrendingUpIcon',
  },

  // SPV
  {
    header: 'SPV Marketing',
    icon: 'UsersIcon',
  },
  {
    title: 'Ajuan Barang',
    route: 'route-ajuan-barang',
    icon: 'PackageIcon',
  },
  {
    title: 'Ajuan BOP',
    route: 'route-biaya',
    icon: 'FileTextIcon',
  },
  {
    title: 'Data Sales',
    route: 'route-sales',
    icon: 'UsersIcon',
  },
  {
    title: 'Data Konsumen',
    route: 'route-konsumen',
    icon: 'UsersIcon',
  },
  {
    title: 'Ajuan Konsumen',
    route: 'route-ajuan-konsumen',
    icon: 'UserCheckIcon',
  },
  {
    title: 'Data Analisis',
    route: 'route-analisis',
    icon: 'TrendingUpIcon',
  },
  {
    title: 'Stok Barang',
    route: 'route-stock-in',
    icon: 'PlusIcon',
  },
  {
    title: 'Barang',
    route: 'route-barang',
    icon: 'PackageIcon',
  },
  {
    title: 'Data Penjualan',
    route: 'route-penjualan',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Data Collection',
    route: 'route-collection',
    icon: 'FilePlusIcon',
  },
  // Gudang
  {
    header: 'Gudang',
    icon: 'UsersIcon',
  },
  // {
  //   header: 'Ajuan',
  //   icon: 'FileTextIcon',
  // children: [
  {
    title: 'Ajuan Barang',
    route: 'route-ajuan-barang',
    icon: 'PackageIcon',
  },
  {
    title: 'Pengambilan Barang',
    route: 'route-ambil-barang',
    icon: 'BoxIcon',
  },
  {
    title: 'Perbaikan Kendaraan',
    route: 'route-perbaikan-kendaraan',
    icon: 'TruckIcon',
  },

  // ],
  // },
  {
    title: 'Stok Barang',
    route: 'route-stock-in',
    icon: 'PlusIcon',
  },
  {
    title: 'Barang',
    route: 'route-barang',
    icon: 'PackageIcon',
  },
  {
    title: 'Checklist Kendaraan',
    route: 'route-checklist-kendaraan',
    icon: 'CheckIcon',
  },
  {
    title: 'Data Penjualan',
    route: 'route-penjualan',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Disposal',
    route: 'route-disposal',
    icon: 'FileMinusIcon',
  },
  // Finance
  {
    header: 'Finance',
    icon: 'DollarSignIcon',
  },
  {
    // title: 'BOP',
    // icon: 'FileTextIcon',
    // children: [
    //   {
    title: 'Ajuan',
    route: 'route-biaya',
    icon: 'FileTextIcon',
  },
  {
    title: 'Pengembalian',
    route: 'route-pengembalian-bop',
    icon: 'FileTextIcon',
  },
  {
    title: 'Reimburse',
    route: 'route-reimburse',
    icon: 'FileTextIcon',
  },

  // ],
  // },
  {
    title: 'Data Collection',
    route: 'route-collection',
    icon: 'FilePlusIcon',
  },
  {
    // title: 'Pengajuan',
    // icon: 'FileTextIcon',
    // children: [
    //   {
    title: 'Pengambilan Barang',
    route: 'route-ambil-barang',
    icon: 'BoxIcon',
  },
  {
    title: 'Perbaikan Kendaraan',
    route: 'route-perbaikan-kendaraan',
    icon: 'TruckIcon',
  },

  //   ],
  // },
  {
    title: 'Uang Kas',
    route: 'route-kas',
    icon: 'BookOpenIcon',
  },
  {
    title: 'Data Penjualan',
    route: 'route-penjualan',
    icon: 'ShoppingCartIcon',
  },

  {
    header: 'Human Resource',
    icon: 'UsersIcon',
  },
  {
    title: 'Data Karyawan',
    route: 'route-karyawan',
    icon: 'UserPlusIcon',
  },
  {
    title: 'Pinjaman',
    route: 'route-pinjaman',
    icon: 'LayersIcon',
  },
  {
    title: 'Gaji Karyawan',
    route: 'route-gaji',
    icon: 'FileIcon',
  },
  {
    title: 'Data Rekap Gaji',
    route: 'route-rekap-gaji',
    icon: 'FileIcon',
  },
  {
    title: 'Insentif',
    route: 'route-insentif',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'Jabatan',
    route: 'route-jabatan',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'Pencapaian',
    route: 'route-pencapaian',
    icon: 'DollarSignIcon',
  },

  {
    header: 'General Manager',
    icon: 'UsersIcon',
  },
  {
    title: 'Data Analisis',
    route: 'route-analisis',
    icon: 'TrendingUpIcon',
  },
  {
    title: 'Data Teritori',
    route: 'route-teritori',
    icon: 'TrendingUpIcon',
  },
  {
    title: 'Data Gudang',
    route: 'route-gudang',
    icon: 'TrendingUpIcon',
  },
  {
    title: 'Data Blok',
    route: 'route-blok',
    icon: 'PackageIcon',
  },
  {
    title: 'Data Rak',
    route: 'route-rak',
    icon: 'LayoutIcon',
  },
  {
    title: 'Data Laci',
    route: 'route-laci',
    icon: 'ArchiveIcon',
  },
  {
    title: 'Kendaraan',
    route: 'route-kendaraan',
    icon: 'TruckIcon',
  },
  {
    title: 'Kendaraan Barang',
    route: 'route-kendaraanbarang',
    icon: 'TrendingUpIcon',
  },
  {
    title: 'Vendor',
    route: 'route-vendor',
    icon: 'ShoppingBagIcon',
  },
  {
    title: 'Barang',
    route: 'route-barang',
    icon: 'PackageIcon',
  },
  {
    header: 'Owner',
    icon: 'UsersIcon',
  },
  {
    title: 'Vendor',
    route: 'route-vendor',
    icon: 'ShoppingBagIcon',
  },
  {
    title: 'Pengguna',
    route: 'route-pengguna',
    icon: 'UsersIcon',
  },
  {
    title: 'Barang',
    route: 'route-barang',
    icon: 'PackageIcon',
  },

]
